import type { Env } from '../types/env';

export const environment: Env = {
  production: true,
  middleware: 'middleware-sg-demo/',
  api: 'https://apim.demo.dynatex.io/',
  cmsProxyTarget: 'https://cms.demo.dynatex.io/api/',
  log: true,
  useCmsProxy: true,
  VAPIDPublicKey: 'BLFAg2gzk-M9coX5RIuwygmEcr0bSomyLlFYLpdL74YqFKRGgnF9tEatk9-fM-cknKO69PJgLlJUFMgAPhI8huc',
  name: 'sg-demo',
};
